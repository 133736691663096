import {Box, Flex, Text} from "@chakra-ui/react";
import React from "react";

const WaterMark = ({font}: {font?: string}) => {
    const offset = -160;
    return (
        <Box fontSize={font? font : "40px"} fontWeight={"bold"} color={"rgba(0,0,0,0.08)"} pos={"absolute"} transform={"rotate(-60deg)"} left={"0"} right={"0"} top={"0"}>
            <Row/>
            <Row offset={offset}/>
            <Row/>
            <Row offset={offset}/>
            <Row/>
            <Row offset={offset}/>
            <Row/>
            <Row offset={offset}/>
            <Row/>
            <Row offset={offset}/>
            <Row/>
            <Row offset={offset}/>
            <Row/>
        </Box>
    )
}

const Row = ({offset}: {offset?: number}) => {
    return (
        <Flex gap={"8px"} transform={"translateX(" + offset + "px)"}>
            <Text>
                PHCWORKHUB
            </Text>
            <Text>
                PHCWORKHUB
            </Text>
            <Text>
                PHCWORKHUB
            </Text>
        </Flex>
    )
}


export default WaterMark