import {IReceipt} from "../../Types/Interface/IReceipt";
import {reqClient} from "../../Api/reqClient";
import {IFormResponse} from "../../Types/Interface/IForm";

const submitReceipt = async (data: IReceipt, guid?: string): Promise<IFormResponse> => {
    const url = `/receipts/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const body = {
        taxAmount: typeof data.taxAmount === "string" ? 0 : data.taxAmount,
        discountAmount: typeof data.discountAmount === "string" ? 0 : data.discountAmount,
        discountPercentage: typeof data.discountPercentage === "string" ? 0 : data.discountPercentage,
        invoiceNumber: data.invoiceNumber,
        note: data.note,
        dueDate: data.dueDate,
        receiptDate: data.receiptDate,
        from: {
            ...data.from,
            phoneNumber: data.from.phoneNumber.toString(),
            isCompletedForm: true,
            address: {
                ...data.from.address,
                isCompletedForm: true,
                country: "United state"
            },
            ein: "",
            taxId: "",
            isContractor: true
        },
        for: {
            ...data.for,
            isCompletedForm: true,
            phoneNumber: data.for.phoneNumber.toString(),
            address: {
                ...data.for.address,
                isCompletedForm: true,
                country: "United state"
            },
            ein: "",
            taxId: "",
            isContractor: true
        },
        lineItems: data.lineItems.map((lineItem, index) => ({...lineItem, id: index, isCompletedForm: true})),
        quoteHtmlString: "",
        email: data.from.email
    }
    const response = await reqClient.post(url, body)
    return response.data
}


export default submitReceipt