import React, { forwardRef } from "react";
import { useCalculateInvoice } from "Hooks";
import { Document } from "Store/Slices";
import WaterMark from "./WaterMark";
import { currencyFormatter } from "Services/utils";


interface ITableProps {
    values: Document;
    logoUrl?: string;
}

export const QuotesTemplate = forwardRef(function Quotes (props: ITableProps & { hasPaid?: boolean }, ref: React.Ref<HTMLDivElement>){

    const { subtotal, discount, itemsLineTotal, tax, total } = useCalculateInvoice(
        props.values.lineItems,
        props.values.discountPercentage,
        props.values.discountAmount,
        props.values.taxAmount
    );

    return (
        <div ref={ref} style={{
            fontFamily: 'Inter, sans-serif', padding: '0px'
        }}>
            <div style={{
                border: '1px solid #1fbc6c80', overflow: "clip", position: "relative", maxWidth: '800px', height: 'auto', margin: 'auto', backgroundColor: '#fff'
            }}>
                {
                    !props.hasPaid && <WaterMark font={"96px"} />
                }
                <div style={{
                    padding: '24px', paddingRight: '50px', paddingLeft: '50px', width: 'auto', backgroundColor: '#F9FAFC', display: 'flex',
                    justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', alignItems: 'left' }}>
                        <div style={{ width: '80px', height: '80px', objectFit: 'contain', marginTop: '8px', marginBottom: '3px' }} >
                            {props.logoUrl && <img src={props.logoUrl} alt="Logo" />}
                        </div>
                        <div style={{ paddingLeft: '20px' }}>
                            <p style={{ fontWeight: 600 }}>Billed to</p>
                            <p style={{ fontSize: '14px', fontWeight: 600, color: 'gray' }}>{props.values.for.name}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.for.address.addressLine1}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{`${props.values.for.address.city}, ${props.values.for.address.regionOrState} ${props.values.for.address.postalCode}.`}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.for.email}</p>
                            <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.for.phoneNumber}</p>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        <p style={{ fontSize: '25px', fontWeight: 500, textTransform: 'uppercase' }}>Quote</p>
                        <p style={{ fontSize: '16px', fontWeight: 600, color: 'blue', textTransform: 'capitalize' }}>{props.values.from.name}</p>
                        <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{props.values.from.address.addressLine1}</p>
                        <p style={{ fontSize: '14px', fontWeight: 500, color: 'gray' }}>{`${props.values.from.address.city}, ${props.values.from.address.regionOrState} ${props.values.from.address.postalCode}.`}</p>
                    </div>
                </div>
                <hr style={{ border: '1px solid #EBEDF2', width: '100%' }} />
                <div style={{
                    display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
                    borderRadius: '20px', border: '1px solid #D7DAE0', margin: 'auto', marginTop: '50px', marginBottom: '8px', overflowX: 'auto',
                    width: '90%', minHeight: '600px', height: '100%'
                }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#F9FAFC', borderBottom: '1px solid #D7DAE0' }}>
                                <th style={{ padding: '20px', textAlign: 'left', fontSize: '14px', width: '40%', wordWrap: 'break-word' }}>Services</th>
                                <th style={{ padding: '10px', textAlign: 'left', fontSize: '14px', width: '20%', }}>Qty</th>
                                <th style={{ padding: '10px', textAlign: 'left', fontSize: '14px', width: '20%', }}>Rate</th>
                                <th style={{ padding: '5px', textAlign: 'left', fontSize: '14px', width: '20%', }}>Line total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.values.lineItems.map((item, index) => (
                                <tr key={item.id} style={{ borderBottom: '1px solid #D7DAE0' }}>
                                    <td style={{
                                        padding: '20px', textAlign: 'left', height: "max-content", fontSize: '14px', wordWrap: 'break-word', whiteSpace: 'normal'
                                    }}>{item.description}</td>
                                    <td style={{ padding: '10px', textAlign: 'left', fontSize: '14px' }}>{item.quantity}</td>
                                    <td style={{ padding: '10px', textAlign: 'left', fontSize: '14px' }}> {item.rate !== undefined && item.rate !== null ? currencyFormatter('USD', Number(item.rate).toFixed(2)) : '0.00'}</td>
                                    <td style={{ padding: '10px', textAlign: 'left', fontSize: '14px' }}>
                                        {itemsLineTotal[index].lineTotal !== undefined && itemsLineTotal[index].lineTotal !== null ? currencyFormatter('USD', Number(itemsLineTotal[index].lineTotal).toFixed(2)) : '0.00'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginTop: '70px'
                    }}>
                        <div style={{
                            display: 'flex', justifyContent: 'space-between', width: '100%',
                            paddingLeft: '30px', paddingRight: '30px', paddingTop: '10px', border: '1px solid #D7DAE0',
                        }}>
                            <div>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>Subtotal</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>Discount</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>Tax ({props.values.taxAmount}%)</p>
                            </div>
                            <div>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>{currencyFormatter('USD', subtotal.toFixed(2))}</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>{discount !== undefined && discount !== null ? currencyFormatter('USD', Number(discount).toFixed(2)) : 0}</p>
                                <p style={{ marginBottom: '10px', fontSize: '14px' }}>{currencyFormatter('USD', tax.toFixed(2))}</p>
                            </div>
                        </div>
                        <div style={{
                            width: '100%', height: '50px', backgroundColor: '#F9FAFC', paddingLeft: '30px', paddingRight: '30px', display: 'flex',
                            justifyContent: 'space-between', alignItems: 'center', borderBottomLeftRadius: '20px',
                            borderBottomRightRadius: '20px'
                        }}>
                            <p style={{ color: 'blue', margin: '0', fontSize: '14px' }}>Total</p>
                            <p style={{ color: 'blue', margin: '0', fontSize: '14px', textAlign: 'left' }}>{currencyFormatter('USD', total.toFixed(2))}</p>
                        </div>
                    </div>
                </div>
                <div style={{margin: "16px", display: "flex", flexDirection: "row", justifyContent: "center",  color: "#5E6470", marginBottom: "28px", alignItems: "center", gap: "0px 8px"}}>
                    <svg width="17px" height="18px" viewBox="0 0 17 18" fill="none">
                        <path fillRule="evenodd"
                              d="M3.4488 0.441895C1.58993 0.441895 0.0830078 1.94881 0.0830078 3.80769V13.9051C0.0830078 15.764 1.58993 17.2709 3.4488 17.2709H13.5462C15.4051 17.2709 16.912 15.764 16.912 13.9051V3.80769C16.912 1.94881 15.4051 0.441895 13.5462 0.441895H3.4488ZM8.03001 5.41499C8.03001 5.12457 7.79312 4.88498 7.50577 4.92774C5.6886 5.19762 4.29025 6.85032 4.29025 8.84879V12.2518C4.29025 12.5422 4.52573 12.7777 4.81616 12.7777H7.50411C7.79454 12.7777 8.03001 12.5422 8.03001 12.2518V9.86966C8.03001 9.57923 7.79454 9.34375 7.50411 9.34375H6.1602V8.77804C6.1602 7.87504 6.73104 7.11327 7.51168 6.87536C7.7894 6.79062 8.03001 6.56285 8.03001 6.27229V5.41499ZM12.7047 5.41499C12.7047 5.12457 12.4679 4.88498 12.1805 4.92774C10.3633 5.19762 8.96498 6.85032 8.96498 8.84879V12.2518C8.96498 12.5422 9.20046 12.7777 9.49089 12.7777H12.1788C12.4693 12.7777 12.7047 12.5422 12.7047 12.2518V9.86966C12.7047 9.57923 12.4693 9.34375 12.1788 9.34375H10.8348V8.77804C10.8348 7.87517 11.4058 7.11327 12.1863 6.87536C12.4641 6.79062 12.7047 6.56285 12.7047 6.27229V5.41499Z"
                              fill="#8B919E"/>
                    </svg>

                    <span>
                        Please pay within 15 days of receiving this invoice.
                    </span>
                </div>

                <div style={{
                    padding: '30px', backgroundColor: '#F9FAFC', display: 'flex',
                    justifyContent: 'space-around', alignItems: 'center', height: '50px'
                }}>
                    <p style={{ color: '#5E6470', fontSize: '14px', }}>{props.values.from.websiteUrl}</p>
                    <p style={{ color: '#5E6470', fontSize: '14px', }}>{props.values.from.phoneNumber}</p>
                    <p style={{ color: '#5E6470', fontSize: '14px', }}>{props.values.from.email}</p>
                </div>
            </div>
        </div>
    );
});