import {IFormError, IFormResponse} from "../Types/Interface/IForm";
import {useState} from "react";
import axios from "axios";
import {useToast} from "@chakra-ui/react";
import {renderToast} from "./useToast";
import {RenderProps} from "@chakra-ui/react/dist/types/toast/toast.types";
import {useAppDispatch} from "./useAppDispatch";
import {setGuid} from "../Store/Slices";
import {FormType} from "../Types/Enums/documentTypeEnums";
import {useAppSelector} from "./useAppSelector";

interface Callback<T> {
    (data: T, guid?: string): Promise<IFormResponse>
}

const useFormSubmit = <T>(fn: Callback<T>, formType: FormType) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const dispatch = useAppDispatch()
    const guid = useAppSelector(state => state.document.guid)
    const storedFormType = useAppSelector(state => state.document.formType)
    const toast = useToast()

    const submit = (data: T)=>{
        let formGuid: string | undefined;
        if (storedFormType !== formType){
            formGuid = undefined
        } else {
            formGuid = guid
        }
        setIsLoading(true);
        const fnPromise = fn(data, formGuid)
        toast.promise(fnPromise, {
            success: {
                title: "Success",
                description: "Form submission successful.",
                position: 'top-left',
                render: ({ description}: RenderProps) => renderToast("success", description)
            },
            loading: {
                title: "Loading",
                description: "Form submission in progress.",
                position: 'top-left',
                render: ({ description}: RenderProps) => renderToast("loading", description)
            },
            error: {
                title: "Error",
                description: "An error occurred. Please try again later.",
                position: 'top-left',
                render: ({ description}: RenderProps) => renderToast("error", description)
            },
        },)
        fnPromise.then((res)=>{
            dispatch(setGuid(res.externalId))
            setIsSuccess(true)
        }).catch((err)=>{
            setIsSuccess(false)
            console.log(err)
            if (axios.isAxiosError<IFormError>(err) && err.response){
                const errData = err.response.data
                for (const i of Object.keys(errData.errors)){
                    for (const error of errData.errors[i]){
                        toast({
                            title: `${i}`,
                            description: `${error}`,
                            position: 'top-left',
                            render: ({ description}: RenderProps) => renderToast("error", description)
                        })
                    }
                }
            } else {
                toast({
                    title: `Error`,
                    description: `Unexpected Error, check all inputs, and try again.`,
                    position: 'top-left',
                    render: ({ description}: RenderProps) => renderToast("error", description)
                })
            }
        }).finally(()=>{
            setIsLoading(false)
        })
    }
    return {isLoading, isSuccess, submit}
}


export default useFormSubmit