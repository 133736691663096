import React, {ReactNode} from 'react';
import {useToast as useToaster, Flex, Text, Spinner} from '@chakra-ui/react';

type Status = 'success' | 'error' | 'loading';

type StatusStyleProps = {
  bg: string;
  borderColor: string;
  color: string;
};

const BG_COLOUR: Record<Status, StatusStyleProps> = {
  error: {
    bg: 'white',
    borderColor: 'red',
    color: 'black',
  },
  success: {
    bg: 'white',
    borderColor: 'green',
    color: 'black',
  },
    loading: {
      bg: 'white',
        borderColor: 'white',
        color: "black"
    }
};

export const renderToast = (status: Status, message?: string | ReactNode) => {
  const { bg, borderColor, color } = BG_COLOUR[status];
  return (
      <Flex
          color={color}
          p={3}
          bg={bg}
          borderLeftColor={borderColor}
          borderLeftWidth={3}
          justifyContent="space-between"
          alignItems="center"
          borderRadius="5px"
          borderLeftRadius={0}
          boxShadow="0px 15px 30px rgba(0, 0, 0, 0.08)"
      >
        {
            status === "loading" && (<Spinner size="md" />)
        }
        <Text>{message}</Text>
      </Flex>
  );
}

export const useToast = (time: number = 3000) => {
  const toast = useToaster();

  const alert = (status: Status, message?: string) => 
    toast({
      title: message,
      isClosable: true,
      duration: time,
      position: 'top-left',
      render: () => renderToast(status, message),
    });

  return { alert };
};