import { Box, Divider, Flex, Spinner } from "@chakra-ui/react";
import { memo, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import React from "react"
import WaterMark from "../Templates/WaterMark";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const PdfViewer = memo(function Viewer({ src, onRefresh, documentLoading, isPreview, }: { src?: File, onRefresh: () => void, documentLoading?: boolean, isPreview?: boolean }) {

    const defaultScale = isPreview ? 1.6 : 1.4;
    const [scale, setScale] = useState(defaultScale)
    const [numPages, setNumPages] = useState<number>()
    const pageNumber = 1
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (!documentLoading) {
            setLoaded(true)
        }
    }, [documentLoading]);


    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages)
    }

    return (
        <Box w={isPreview ? "full" : "450px"}>
            <Flex userSelect={"none"} h={"12"} align={"center"} px={"16px"} justifyContent={"space-between"} color={"gray.300"} background={"gray.700"} w={"full"}>
                <Box onClick={() => setScale(1)} cursor={"pointer"}>
                    Reset Zoom
                </Box>
                <Flex gap={"12px"} align={"center"} fontSize={"md"}>
                    <Box w={"24px"} cursor={"pointer"} onClick={() => setScale(prev => prev !== 0.8 ? prev - 0.1 : prev)}>
                        -
                    </Box>
                    <Divider background={"gray.300"} h={"30px"} orientation="vertical" w={"2px"} />
                    <Box w={"24px"} cursor={"pointer"} onClick={() => setScale(prev => (prev + 0.1).toFixed(1) !== '2.0' ? prev + 0.1 : prev)}>
                        +
                    </Box>
                    <Box fontSize={"md"}>Pages: {numPages}</Box>
                </Flex>
                <Box cursor={"pointer"} h={"24px"} w={"24px"} onClick={onRefresh}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                </Box>

            </Flex>
            <Box w={"full"} h={"400px"} overflow={"scroll"} position={"relative"} background={"gray.600"}>
                <Box position={"absolute"} w={"full"} h={"400px"} overflow={"clip"} zIndex={"modal"}>
                    <WaterMark font={"96px"} />
                </Box>
                {
                    loaded ? (
                        <Document
                            noData={<Flex justifyContent={"center"} color={'grey'} align={'center'} w={"full"} h={"300px"}>{documentLoading ? "Document loading" : "Please check your network and refresh"} </Flex>} file={src} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page width={400} scale={scale} renderMode="canvas" pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false}></Page>
                        </Document>
                    ) : <Flex background={"white"} w={"450px"} h={"full"} justifyContent={"center"} align={"center"}><Spinner /></Flex>
                }
            </Box>
        </Box>
    )
})


export default PdfViewer;