import AppLayout from "../../Layouts/AppLayout";
import { Box, Checkbox, Flex, FormControl, FormLabel, Grid, GridItem, Input, Text } from "@chakra-ui/react";
import BusinessFormsHeading from "../Components/Forms/BusinessFormsHeading";
import { PatternFormat } from "react-number-format";
import TextInput from "../Components/Inputs/TextInput";
import { useAppSelector } from "Hooks/useAppSelector";

import {
    select1099Basic,
    set1099InfoValues,
    select1099Payer,
    select1099RecipientLength,
    set1099MiscPayerValues,
    set1099MiscPayerAddress,
    select1099PayerAddress,
    set1099MiscRecipientOptions,
    select1099RecipientOptions,
    set1099MiscRecipientValues,
    select1099Recipient,
    select1099RecipientAddress,
    set1099MiscRecipientAddress, select1099State, reset1099Misc
} from "Store/Slices/1099MiscSlice";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { I1099MiscBasic, IPayer, IRecipient, IRecipientOptions } from "Types/Interface/I1099Misc";
import DropSelect from "../Components/Inputs/DropSelect";
import useFormCounter from "../../Hooks/useFormCounter";
import FormPreviewLayout from "../../Layouts/FormPreviewLayout";
import FormsLayout from "../../Layouts/FormsLayout";
import FormHeading from "../Components/FormHeading";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import { IAddress } from "../../Types";
import StateDropSelect from "../Components/StateDropSelect";
import PdfViewer from "../Components/PdfViewer";
import useFill1099Misc from "../../Hooks/useFill1099Misc";
import FormPreviewBar from "../Components/FormPreviewBar";
import {setDocumentCount, setDocumentType, setFormType} from "../../Store/Slices";
import { documentTypeEnums, FormType } from "../../Types/Enums/documentTypeEnums";
import { AppRoutes } from "../../Routes/AppRoutes";
import { useToast } from "../../Hooks/useToast";
import { useNavigate } from "react-router-dom";
import useFormSubmit from "../../Hooks/useFormSubmit";
import submit1099Misc from "../../Services/api/F1099Misc";

const F1099 = () => {
    const { email, year, formCount, accept, agree } = useAppSelector(select1099Basic)
    const { name, tin } = useAppSelector(select1099Payer)
    const { stateAddress, city, zipcode, state } = useAppSelector(select1099PayerAddress)
    const dispatch = useAppDispatch()
    const formCounts = useFormCounter()
    const f1099Data = useAppSelector(select1099State)
    const { data: miscData, refreshData } = useFill1099Misc(f1099Data)
    const { alert } = useToast()
    const navigate = useNavigate();
    const {downloaded} = f1099Data
    const {submit, isSuccess, isLoading} = useFormSubmit(submit1099Misc, FormType.F1099)

    useEffect(() => {
        // resets form state once user downloaded data already
        if (downloaded){
            dispatch(reset1099Misc())
        }
    }, [downloaded])

    useEffect(() => {
        if (isSuccess){
            dispatch(setDocumentType(documentTypeEnums.Receipt))
            dispatch(setDocumentCount(1))
            dispatch(setFormType(FormType.Receipt))
            navigate(AppRoutes.checkout);
        }
    }, [isSuccess]);

    const handleBasicInfoChange = (name: string, value: string | boolean | number) => {
        dispatch(set1099InfoValues({ field: name as keyof I1099MiscBasic, value }));
    };
    const handlePayerInfo = (name: string, value: string) => {
        dispatch(set1099MiscPayerValues({ field: name as keyof Omit<IPayer, "address">, value }))
    }

    const handlePayerAddress = (name: string, value: string) => {
        dispatch(set1099MiscPayerAddress({ field: name as keyof IAddress, value }))
    }

    const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
        e?.preventDefault();
        if (!agree || !accept) {
            alert("error", "Accept our TOS and agree to not use it for illegal activities")
        } else {
            handleBasicInfoChange("agree", false)
            handleBasicInfoChange("accept", false)
            if (!isLoading){
                submit(f1099Data)
            }
        }
    }

    return (
        <AppLayout isForm>
            <form onSubmit={handleSubmit}>

                <Flex minH={"100vh"} w={"full"} direction={{ base: "column", md: "row" }} px={"50px"} py={"50px"}
                    gap={"32px"} pos={"relative"}>

                    <FormsLayout>
                        <FormHeading title={"Create 1099"} description={"Fill in your information below"} />
                        <BusinessFormsHeading title={"Basic Information"} steps={2} currentStep={1} />
                        <Box mb={"56px"}>
                            <Flex gap={"16px"}>
                                <FormControl isRequired mt={"20px"}>
                                    <FormLabel>
                                        Tax year
                                    </FormLabel>
                                    <PatternFormat
                                        focusBorderColor="primary"
                                        customInput={Input}
                                        value={year}
                                        onChange={(e) => handleBasicInfoChange(e.target.name, e.target.value)}
                                        name={'year'}
                                        format={"####"}
                                        type={"text"}
                                        placeholder={"YYYY"} />
                                </FormControl>
                                <TextInput isRequired label={"Email"} value={email} onChange={(e) => handleBasicInfoChange(e.target.name, e.target.value)} type={"email"} name={'email'} />
                            </Flex>
                            <DropSelect width={"50%"} isRequired flexBasis={"60%"} value={formCount} onChange={(event) => handleBasicInfoChange(event.target.name, parseInt(event.target.value))} label={"How many forms do you need?"} name={'formCount'}>
                                {
                                    formCounts.slice(0, 3).map((count) => <option key={count} value={count + 1}>{count + 1}</option>)
                                }
                            </DropSelect>
                        </Box>
                        <BusinessFormsHeading title={"1099 Misc form"} steps={2} currentStep={2} />
                        <Box mb={"56px"}>
                            <Text textTransform={"uppercase"} fontSize={"20px"} color={"primary"} borderBottom={"1px"} w={"80%"} mt={"24px"} borderBottomColor={"primary"}>
                                Payer&apos;s Info
                            </Text>
                            <Flex gap={"42px"}>
                                <TextInput isRequired flexBasis={"60%"} value={name} label={"Payer's Name"} onChange={(e) => handlePayerInfo(e.target.name, e.target.value)} name={"name"} />
                                <FormControl isRequired mt={"24px"} flexBasis={"40%"}>
                                    <FormLabel>
                                        Payer&apos;s Tin(Company EiN)
                                    </FormLabel>
                                    <PatternFormat
                                        focusBorderColor="primary"
                                        customInput={Input}
                                        value={tin}
                                        onChange={(e) => handlePayerInfo(e.target.name, e.target.value)}
                                        name={'tin'}
                                        format={"##-#######"}
                                        type={"text"}
                                        isRequired
                                        placeholder={"Ein / Tax Id"} />
                                </FormControl>
                            </Flex>
                            <Flex>
                                <TextInput isRequired flexBasis={"60%"} value={stateAddress} label={"Address"} onChange={(e) => handlePayerAddress(e.target.name, e.target.value)} name={"stateAddress"} />
                            </Flex>
                            <Flex gap={"42px"}>
                                <TextInput isRequired flexBasis={"40%"} value={city} label={"City"} onChange={(e) => handlePayerAddress(e.target.name, e.target.value)} name={"city"} />
                                <StateDropSelect flexBasis={"40%"} name={"state"} value={state} label={"State"} onChange={(e) => handlePayerAddress(e.target.name, e.target.value)} notFormik={true} />
                                <TextInput isRequired flexBasis={"30%"} value={zipcode} label={"Zip Code"} onChange={(e) => {
                                    if (e.target.value.length <= 5) {
                                        e.target.value = e.target.value.slice(0, 5)
                                        handlePayerAddress(e.target.name, e.target.value)
                                    }
                                }
                                } name={"zipcode"} />
                            </Flex>
                        </Box>
                        <RecipientsForm />
                    </FormsLayout>
                    <FormPreviewLayout>
                        <Box >
                            <PdfViewer src={miscData} onRefresh={() => { refreshData() }}></PdfViewer>
                            <Box borderBottom={"1px"} pb={"24px"} mt={"24px"} borderBottomColor={"#00000080"}>
                                <Checkbox name={"agree"} isChecked={agree} onChange={() => handleBasicInfoChange("agree", !agree)} mb={"12px"}> I agree not to use this site for illegal purposes</Checkbox>
                                <Checkbox isChecked={accept} onChange={() => handleBasicInfoChange("accept", !accept)} name={"accept"}> I accept our Term of Use and Privacy Policy</Checkbox>
                            </Box>
                            <FormPreviewBar submit={() => handleSubmit()} setDocumentType={() => dispatch(setDocumentType(documentTypeEnums.F1099))} background={!(accept && agree) ? "grey" : "primary"} disabled={!(accept && agree)} formType={FormType.F1099} />
                        </Box>
                    </FormPreviewLayout>
                </Flex>
            </form>
        </AppLayout>
    )
}

const RecipientsForm = () => {
    const length = useAppSelector(select1099RecipientLength)
    return (
        <>
            {

                Array.from(Array(length)).map((_, index) => <RecipientForm key={index} index={index} />)
            }
        </>
    )
}

const RecipientForm = ({ index }: { index: number }) => {
    const dispatch = useAppDispatch()
    const { name, tin } = useAppSelector(state => select1099Recipient(state, index))
    const { stateAddress, state, city, zipcode } = useAppSelector(state => select1099RecipientAddress(state, index))
    const options = useAppSelector(state => select1099RecipientOptions(state, index))
    const [showDetails, setShowDetails] = useState(false)
    const handleInput = (name: string, value: string | boolean) => {
        dispatch(set1099MiscRecipientOptions({ field: name as keyof IRecipientOptions, index, value }))
    }
    const handleToFixed = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        let value = e.target.value
        const name = e.target.name
        if (value) {
            value = parseFloat(value).toFixed(2).toString()
            dispatch(set1099MiscRecipientOptions({ field: name as keyof IRecipientOptions, index, value }))

        }
    }
    const handleRecipientInfo = (name: string, value: string) => {
        dispatch(set1099MiscRecipientValues({ field: name as keyof Omit<IRecipient, "address" | "options" | "id">, index, value }))
    }
    const handleRecipientAddress = (name: string, value: string) => {
        dispatch(set1099MiscRecipientAddress({ field: name as keyof IAddress, index, value }))
    }

    return (
        <Box key={index}>
            <Text textTransform={"uppercase"} fontSize={"20px"} color={"primary"} borderBottom={"1px"} w={"80%"} mt={"24px"} borderBottomColor={"primary"}>
                Recipient&apos;s Info
            </Text>
            <Flex gap={"42px"}>
                <TextInput isRequired flexBasis={"60%"} value={name ?? ""} label={"Recipient's Name"} onChange={(e) => handleRecipientInfo(e.target.name, e.target.value)} name={"name"} />
                <FormControl isRequired mt={"20px"} flexBasis={"40%"}>
                    <FormLabel>
                        {"Recipient's Tin(Social security)"}
                    </FormLabel>
                    <PatternFormat
                        focusBorderColor="primary"
                        customInput={Input}
                        value={tin ?? ""}
                        format={"###-##-####"}
                        onChange={(e) => handleRecipientInfo(e.target.name, e.target.value)}
                        name={"tin"}
                        type={"text"}
                        placeholder={"Ein / Tax Id"} />
                </FormControl>
            </Flex>
            <Flex gap={"42px"}>
                <TextInput isRequired flexBasis={"60%"} value={stateAddress ?? ""} label={"Address"} onChange={(e) => handleRecipientAddress(e.target.name, e.target.value)} name={"stateAddress"} />
            </Flex>
            <Flex gap={"42px"}>
                <TextInput isRequired flexBasis={"40%"} value={city ?? ""} label={"City"} onChange={(e) => handleRecipientAddress(e.target.name, e.target.value)} name={"city"} />
                <StateDropSelect flexBasis={"40%"} name={"state"} value={state} label={"State"} onChange={(e) => handleRecipientAddress(e.target.name, e.target.value)} notFormik={true} />
                <FormControl isRequired mt={"20px"} flexBasis={"30%"}>
                    <FormLabel>
                        {"Zipcode"}
                    </FormLabel>
                    <PatternFormat
                        focusBorderColor="primary"
                        customInput={Input}
                        value={zipcode ?? ""}
                        format={"#####"}
                        onChange={(e) => handleRecipientAddress(e.target.name, e.target.value)}
                        name={"zipcode"}
                        type={"text"}
                        placeholder={"Zipcode"}
                        maxLength={5} />
                </FormControl>
            </Flex>
            <Text textTransform={"uppercase"} fontSize={"20px"} color={"primary"} borderBottom={"1px"} w={"80%"} mt={"24px"} borderBottomColor={"primary"}>
                Recipient&apos;s Options
            </Text>
            <Grid templateColumns={"repeat(3, 1fr)"} gap="6" alignContent={'stretch'}>
                <GridItem>
                    <TextInput flexBasis={"40%"} value={options.rents ?? ""} label={"01 Rents"} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"rents"} type={"number"} onBlur={handleToFixed} />
                </GridItem>
                <GridItem>
                    <TextInput flexBasis={"30%"} value={options.royalties ?? ""} label={"02 Royalties"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"royalties"} type={"number"} />
                </GridItem>
                <GridItem>
                    <TextInput flexBasis={"30%"} value={options.otherIncome ?? ""} label={"03 Other Incomes"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"otherIncome"} type={"number"} />
                </GridItem>
                <GridItem mt={"32px"} colSpan={3} justifySelf={"end"}>
                    <Checkbox isChecked={showDetails} onChange={() => setShowDetails((prevState) => !prevState)}>
                        Show Additional options
                    </Checkbox>
                </GridItem>
                {
                    showDetails && (
                        <>
                            <GridItem>
                                <TextInput flexBasis={"40%"} value={options.federalTaxIncomeWithheld ?? ""} label={"04 Federal income tax withheld"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"federalTaxIncomeWithheld"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.fishingBoatProceeds ?? ""} label={"05 Fishing boat proceeds"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"fishingBoatProceeds"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.medicalHealthPayments ?? ""} label={"06 Medical and health care payment"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"medicalHealthPayments"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <Checkbox isChecked={options.directSalesHigh ?? false} onChange={(e) => handleInput(e.target.name, !options.directSalesHigh)} name={"directSalesHigh"}>07 Payer made direct sales totaling $5000 or more of consumer products</Checkbox>
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.subPayments ?? ""} label={"08 Substitute payments in lieu of dividends or interest"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"subPayments"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.cropInsurance ?? ""} label={"09 Crop insurance proceeds"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"cropInsurance"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"40%"} value={options.grossProceeds ?? ""} label={"10 Gross proceeds paid to an attorney"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"grossProceeds"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.fishPurchased ?? ""} label={"11 Fish purchased for resale"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"fishPurchased"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.section409 ?? ""} label={"12 Section 409A deferrals"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"section409"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <Checkbox isChecked={options.FATCAFiling ?? false} onChange={(e) => handleInput(e.target.name, !options.FATCAFiling)} name={"FATCAFiling"}>13 FATCA filing requirement</Checkbox>
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.goldenParachute ?? ""} label={"14 Excess golden parachute payments"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"goldenParachute"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.nonQualifiedCompensation ?? ""} label={"15 Nonqualified deferred compensation"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"nonQualifiedCompensation"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"40%"} label={"16 State tax withheld"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"stateTax"} type={"number"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.statePayerNo ?? ""} label={"17 State/Payers state no"} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"statePayerNo"} />
                            </GridItem>
                            <GridItem>
                                <TextInput flexBasis={"30%"} value={options.stateIncome ?? ""} label={"18 State income"} onBlur={handleToFixed} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"stateIncome"} type={"number"} />
                            </GridItem>
                            <GridItem colSpan={3} justifySelf={"center"}>
                                <TextInput value={options.accountNumber ?? ""} label={"Account Number(See instructions)"} onChange={(e) => handleInput(e.target.name, e.target.value)} name={"accountNumber"} />
                            </GridItem>
                        </>
                    )
                }
            </Grid>
        </Box>
    )
}


export default F1099