import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import { documentTypeEnums } from 'Types/Enums/documentTypeEnums';
import { IQuotes } from 'Types/Interface/IQuotes'
import { IReceipt } from 'Types/Interface/IReceipt';
import {FormType} from "../../Types/Enums/documentTypeEnums";


 export type Document = IQuotes | IReceipt
interface IInitialDocumentState{
    documentData: Document | null;
    documentType: number;
    logo: string
    subTotal: number;
    tax: number;
    discount: number;
    total: number;
    formType: FormType | null;
    documentCount: number;
    note?: string
    downloaded?: boolean;
    guid?: string;
    email?: string;
    coinbaseSession?: string;
}


const initialState: IInitialDocumentState = {
    documentData: null,
    documentType: 0,
    logo: '',
    subTotal: 0,
    tax: 0,
    discount: 0,
    total: 0,
    documentCount: 1,
    formType: null,
    downloaded: false
}

const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocumentType: (state, action: PayloadAction<number>) => {
            state.documentType = action.payload;
        },
        setDocumentData: (state, action: PayloadAction<Partial<Document>>) => {
            state.documentData = { ...state.documentData, ...action.payload } as Document;
        },
        setLogo: (state, action: PayloadAction<string>) => {
            state.logo = action.payload;
        },
        setTax: (state, action: PayloadAction<number>) => {
            state.tax = action.payload;
        },
        setDiscount: (state, action: PayloadAction<number>) => {
            state.discount = action.payload;
        },
        setSubTotal: (state, action: PayloadAction<number>) => {
            state.subTotal = action.payload;
        },
        setTotal: (state, action: PayloadAction<number>) => {
            state.total = action.payload;
        },
        resetDocumentState: () => {
            return initialState
        },
        setFormType: (state, action: PayloadAction<FormType>) => {
            state.formType = action.payload;
        },
        setDocumentCount: (state, action: PayloadAction<number>) => {
            state.documentCount = action.payload
        },
        setDocumentDownloaded: (state) => {
            state.downloaded = true
        },
        resetLogo: (state) => {
            state.logo = initialState.logo
        },
        setGuid: (state, action: PayloadAction<string | undefined>) => {
            state.guid = action.payload
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
        setCoinbaseSession: (state, action: PayloadAction<string | undefined>) => {
            state.coinbaseSession = action.payload
        }
    },
});

export const {
    setDocumentType,
    setDocumentData,
    setLogo,
    setDiscount,
    setSubTotal,
    setTax,
    setTotal,
    resetDocumentState,
    setFormType,
    setDocumentCount,
    setDocumentDownloaded,
    resetLogo,
    setGuid,
    setEmail,
    setCoinbaseSession
} = documentSlice.actions;
export default documentSlice.reducer