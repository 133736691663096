import { configureStore } from "@reduxjs/toolkit";
import receiptReducer from "./Slices/receiptSlice";
import quotesReducer from "./Slices/documentSlice";
import documentReducer from './Slices/documentSlice';
import w2Reducer from "./Slices/w2Slice"
import f1099MiscReducer from "./Slices/1099MiscSlice"
import f1099NecReducer from "./Slices/1099NecSlice"
import { priceApi, receiptApi, quotesApi, imageUploadApi } from "Services";
import { stripeApi } from "Services/stripe";
import {apiSlice} from "./Slices/apiSlice";
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { coinbaseApi } from "Services/coinbase";
import templateSlice from "./Slices/templateSlice";
import paystubSlice from "./Slices/paystubSlice";

const PERSISTED_VERSION = '1.0.0';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [
    imageUploadApi.reducerPath,
    stripeApi.reducerPath,
    coinbaseApi.reducerPath
  ],
};

const rootReducer = combineReducers({
    receipt: receiptReducer,
    quotes: quotesReducer,
    document: documentReducer,
    w2: w2Reducer,
    template: templateSlice,
    f1099Misc: f1099MiscReducer,
    f1099Nec: f1099NecReducer,
    payStub: paystubSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [receiptApi.reducerPath]: receiptApi.reducer,
    [quotesApi.reducerPath]: quotesApi.reducer,
    [priceApi.reducerPath]: priceApi.reducer,
    [imageUploadApi.reducerPath]: imageUploadApi.reducer,
    [stripeApi.reducerPath]: stripeApi.reducer,
    [coinbaseApi.reducerPath]: coinbaseApi.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }
    ).concat(
      ...[
        receiptApi.middleware,
        quotesApi.middleware,
        priceApi.middleware,
        imageUploadApi.middleware,
        stripeApi.middleware,
        coinbaseApi.middleware,
          apiSlice.middleware
      ]
    ),
});

export const persistor = persistStore(store);

const storedVersion = localStorage.getItem('reduxVersion');

if (storedVersion !== PERSISTED_VERSION) {
    persistor.purge().then(() => {
        localStorage.setItem('reduxVersion', PERSISTED_VERSION);
        // console.log('Redux Persist Store Purged due to version change');
    });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
