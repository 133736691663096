import { useEffect } from "react"
import { useAppDispatch } from "./useAppDispatch"
import { useAppSelector } from "./useAppSelector";
import { selectEmployee, setRecalculateMedicareAndSocialSecurityTax } from "Store/Slices/w2Slice";

const useEmployeeTaxCode = (index: number) => {
    const dispatch = useAppDispatch();
    const employeeInfo = useAppSelector(state => selectEmployee(state, index))

    useEffect(() => {

        dispatch(setRecalculateMedicareAndSocialSecurityTax(index))
    }, [employeeInfo.taxCodeA, employeeInfo.taxCodeB, employeeInfo.taxCodeC, employeeInfo.taxCodeD, employeeInfo.income])
}

export default useEmployeeTaxCode;
