import React, { forwardRef } from "react";
import { useCalculateInvoice } from "Hooks";
import { Document } from "Store/Slices";
import { IReceipt } from "Types/Interface/IReceipt";
import { us_LocaleDateString } from "Services/utils"
import WaterMark from "./WaterMark";

interface IReceiptProps {
    values: Document;
    logoUrl?: string;
}

const isReceipt = (document: Document): document is IReceipt => {
    return (document as IReceipt).receiptDate !== undefined;
};

export const ReceiptTemplate = forwardRef<HTMLDivElement, IReceiptProps & { hasPaid?: boolean }>(function ReceiptTemplate({ values, logoUrl, }, ref) {
    const { subtotal, discount, itemsLineTotal, tax, total } = useCalculateInvoice(
        values.lineItems,
        values.discountPercentage,
        values.discountAmount,
        values.taxAmount
    );

    const commonTextStyle = { margin: '2px 0', color: '#5E6470', fontSize: '14px' };
    const sectionTitleStyle = { marginBottom: '20px', fontWeight: 600 };

    return (
        <div ref={ref} style={{ fontFamily: 'Inter, sans-serif', margin: '0', padding: '0' }}>
            <div style={{ maxWidth: '800px', margin: 'auto', border: '1px solid #ddd',  overflow: "clip", position: "relative", }}>
                <WaterMark font={"96px"} />
                <div style={{ display: 'flex', backgroundColor: '#F9FAFC', padding: '30px', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: "column" }}>
                        <div style={{ width: '50px', height: '50px', objectFit: 'contain', marginTop: '8px', marginBottom: '3px' }}>
                            {
                                logoUrl && <img src={logoUrl} alt="Logo" style={{ width: '50px' }} />
                            }
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <h1 style={{
                                ...commonTextStyle,
                                color: '#4C63ED',
                                textTransform: 'uppercase',
                                fontSize: '25px',
                                fontWeight: 'bold'
                            }}>{values.from.name}</h1>
                            <p style={commonTextStyle}>{values.from.address.addressLine1}</p>
                            <p style={commonTextStyle}>{`${values.from.address.city.length !== 0 ? values.from.address.city + "," : ""} ${values.from.address.regionOrState.length !== 0 ? values.from.address.regionOrState : ""} ${values.from.address.postalCode}.`}</p>
                            <p style={commonTextStyle}>{values.from.email}</p>
                            <p style={commonTextStyle}>{values.from.phoneNumber}</p>
                        </div>
                    </div>
                    <h2 style={{ margin: '0', textAlign: 'right', fontWeight: 'bold', fontSize: '30px' }}>RECEIPT</h2>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', padding: '30px 50px' }}>
                    <div style={{ width: "55%", maxWidth: "55%" }}>
                        <h3 style={sectionTitleStyle}>Customer’s Details</h3>
                        <div>
                            <div style={{ display: 'flex', }}>
                                <p style={commonTextStyle}> {` ${values.for.name}`}</p>
                            </div>
                            <div style={{ display: 'flex', }}>
                                <p style={{ ...commonTextStyle, marginRight: '10px' }}> {`${values.for.address.addressLine1}`} <br/>
                                    {values.for.address.city.length !== 0 ? values.for.address.city + ", " : " "} {values.for.address.regionOrState.length !== 0 ? values.for.address.regionOrState : " "} {values.for.address.postalCode.length !== 0 ? values.for.address.postalCode + "." : ""}
                                    </p>
                            </div>
                            <div style={{ display: 'flex', }}>
                                <p style={commonTextStyle}>{` ${values.for.phoneNumber}`}</p>
                            </div>
                            <div style={{ display: 'flex', }}>
                                <p style={commonTextStyle}>{` ${values.for.email}`}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxWidth: "40%" }}>
                        <h3 style={sectionTitleStyle}>Receipt Details</h3>
                        {isReceipt(values) && (
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <p style={commonTextStyle}><strong>Date: </strong>{` ${us_LocaleDateString(values.receiptDate)}`}</p>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <p style={commonTextStyle}><strong>Invoice: </strong>{` ${values.invoiceNumber}`}</p>
                                </div>
                                {/* <div style={{ display: 'flex' }}>
                                    <p style={commonTextStyle}><strong>Date Due: </strong>{` ${us_LocaleDateString(values.dueDate)}`}</p>
                                </div> */}
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ padding: '20px 50px 50px 60px' }}>
                    <table style={{ width: '100%', marginTop: '10px', borderCollapse: 'collapse', fontSize: "0.85rem" }}>
                        <thead>
                            <tr style={{ backgroundColor: '#F9FAFC' }}>
                                <th style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center', width: "15%" }}>Quantity</th>
                                <th style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center', width: "50%" }}>Item Description</th>
                                <th style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center', width: "15%" }}>Unit Price</th>
                                <th style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center', width: "20%" }}>Line Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {values.lineItems.map((item, index) => (
                                <tr key={item.id}>
                                    <td style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>{item.quantity}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>{item.description}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>{item.rate}</td>
                                    <td style={{ border: '1px solid #ddd', padding: '4px', textAlign: 'center' }}>${itemsLineTotal[index].lineTotal.toFixed(2)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <p style={{ marginTop: '20px', fontSize: '14px' }}><strong>Note: </strong>{values.note}</p>
                    <p style={{ marginTop: '20px', fontWeight: 'bold', fontSize: '16px' }}>Thanks for the business.</p>
                </div>

                <div style={{ marginTop: '20px', backgroundColor: '#F9FAFC', display: 'flex', justifyContent: 'space-between', padding: '20px 60px' }}>
                    <div style={{ textAlign: 'left' }}>
                        <p style={commonTextStyle}>Subtotal </p>
                        <p style={commonTextStyle}>Discount </p>
                        <p style={commonTextStyle}>Sales Tax </p>
                        <p style={{ ...commonTextStyle, color: '#4C63ED' }}>Total </p>
                    </div>
                    <div>
                        <p style={commonTextStyle}>${subtotal.toFixed(1)}</p>
                        <p style={commonTextStyle}>${discount !== undefined && discount !== null ? parseFloat(discount.toString()).toFixed(2) : '0.00'}</p>
                        <p style={commonTextStyle}>${tax.toFixed(1)}</p>
                        <p style={{ ...commonTextStyle, color: '#4C63ED' }}>${total.toFixed(1)}</p>
                    </div>
                </div>
                <div style={{ backgroundColor: '#F9FAFC', display: 'flex', justifyContent: 'center', padding: '20px 60px', color:"GrayText"}}>
                    <p style={{ fontSize: '12px' }}><strong>{values.from.websiteUrl}</strong></p>
                </div>
            </div>
        </div>
    );
});