import {IFormResponse} from "../../Types/Interface/IForm";
import I1099Misc from "../../Types/Interface/I1099Misc";
import {transformMiscData} from "../../Hooks/useFill1099Misc";
import {reqClient} from "../../Api/reqClient";

const submit1099Misc = async (data: I1099Misc, guid?: string): Promise<IFormResponse> => {
    const url = `/_1099Misc/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const transformedData = transformMiscData(data)
    const body = {
        "_1099MISCDto": {
            ...transformedData._1099MISCDto,
            payer: {...transformedData._1099MISCDto.payer, isCompletedForm: true},
            recipient: {
                ...transformedData._1099MISCDto.recipient, isCompletedForm: true
            },
        }
    }

    const response = await reqClient.post(url, body)
    return response.data
}

export default submit1099Misc