import {
    Box,
    Flex,
    FormControl, FormLabel,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper
} from "@chakra-ui/react";
import BusinessFormsHeading from "./BusinessFormsHeading";
import React, {useState} from "react"
import { useFormikContext } from "formik";
import {Document} from "../../../Store/Slices";

const BusinessTaxDeduction = () => {

    const {values, handleBlur, setFieldValue } = useFormikContext<Document>();
    const [tax, setTax] = useState(`${values.taxAmount}`)
    const [discountPercent, setDiscountPercent] = useState(`${values.discountPercentage}`)
    const [discount, setDiscount] = useState(`${values.discountAmount}`)

    return (

        <Box mb={"32px"}>
            <BusinessFormsHeading title={"Add Deduction"} />
            <Flex alignItems={"center"} gap={"20px"} justifyContent={"space-between"}>
                <FormControl>
                    <FormLabel>
                        Tax %
                    </FormLabel>
                    <NumberInput defaultValue={0} precision={2} step={0.1}
                                 onChange={(value)=> {
                                     setTax(value)
                                     setFieldValue("taxAmount", value.length !== 0 ? parseFloat(value) : 0)
                                 }}
                                 onBlur={handleBlur} value={tax}>
                        <NumberInputField name={"taxAmount"}/>
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Discount %
                    </FormLabel>
                    <NumberInput defaultValue={0} precision={2} step={0.1}
                                 onChange={(value) => {
                                     setDiscountPercent(value)
                                     setFieldValue("discountPercentage", value.length !== 0 ? parseFloat(value) : 0)
                                     setDiscount("0")
                                 }}
                                 onBlur={handleBlur}
                                 value={discountPercent}>
                        <NumberInputField name={"discountPercentage"}  />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
                <FormControl>
                    <FormLabel>
                        Discount Amount
                    </FormLabel>
                    <NumberInput name={"discountAmount"} defaultValue={0} precision={2} step={0.1}
                                 onChange={(value) => {
                                     setDiscount(value)
                                     setFieldValue("discountAmount", value.length !== 0 ? parseFloat(value) : 0)
                                     setDiscountPercent("0")
                                 }}
                                 onBlur={handleBlur} value={discount}>
                        <NumberInputField name={"discountAmount"}  />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                </FormControl>
            </Flex>
        </Box>
    )
}

export default BusinessTaxDeduction