import {Text, View} from "@react-pdf/renderer";
import React from "react";

const PdfWaterMark = ({fontSize} : {fontSize?: string | number | undefined}) => {
    return (
        <View style={{ position: 'absolute',
            left: 0,
            right: 0,
            height: "100%",
            overflow: "hidden",
            transform: 'translate(-50%, -50%) rotate(-30deg)',
            fontSize: fontSize ?? "200px",
            color: 'rgba(31,188,108,0.1)',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Inter",
            fontWeight: "bold",}}>
            <Row/>
        </View>
    )
}

const Row = ({offset}: {offset?: number}) => {
    return (
        <View style={{flexDirection: "row", gap: "8px", }}>
            <Mark/>
        </View>
    )
}

const Mark = () => {
    return (
        <Text wrap={false} style={{
            textTransform: "uppercase"
        }}>
            PhcWorkPro
        </Text>
    )
}

export default PdfWaterMark