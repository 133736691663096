import {useAppSelector} from "../Hooks/useAppSelector";
import React, { useRef} from "react";
import {Box, Button, Center} from "@chakra-ui/react";
import PdfViewer from "../Pages/Components/PdfViewer";
import useFill1099Misc from "../Hooks/useFill1099Misc";
import {select1099State, setMiscDownloaded} from "../Store/Slices/1099MiscSlice";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";
import {useAppDispatch} from "../Hooks/useAppDispatch";
import useVerifyPayment from "../Hooks/useVerifyPayment";


const F1099MiscDocument = ({hasPaid, session}: {hasPaid?: boolean, session?: IDocumentCheckout}) => {
    const f1099Data = useAppSelector(select1099State)
    const { data: miscData, refreshData, loading } = useFill1099Misc(f1099Data)
    const anchorRef = useRef<HTMLAnchorElement>(null)
    const dispatch = useAppDispatch()
    const {isSuccess, checkoutLoading} = useVerifyPayment(hasPaid, session)


    const downloadPdf = () => {
        const pdfUrl = URL.createObjectURL(miscData as Blob)
        if (anchorRef.current){
            anchorRef.current.href = pdfUrl
            anchorRef.current.download = "1099 Misc"
            anchorRef.current.click()
        }
        dispatch(setMiscDownloaded())
    }

    if (hasPaid && session) {
        return (
            <>
                {
                    checkoutLoading ? (
                        <Button>
                            Verifying payment...
                        </Button>
                    ) : (
                        isSuccess ? (
                            <>
                                <Box position="absolute" top="-9999px" left="-9999px">
                                    <a href={'#'} ref={anchorRef}>Download file</a>
                                </Box>
                                <Button onClick={downloadPdf}>
                                    Download 1099 Misc
                                </Button>
                            </>
                        ) : <Center>An error occurred, could not verify payment</Center>
                    )
                }
            </>
        )
    }

    return (
        <PdfViewer isPreview src={miscData} documentLoading={loading} onRefresh={() => { refreshData() }}></PdfViewer>
    )
}

export default F1099MiscDocument