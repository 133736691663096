import React, { ChangeEvent } from "react"
import BusinessFormsHeading from "../../Components/Forms/BusinessFormsHeading";
import { Box, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import TextInput from "../../Components/Inputs/TextInput";
import StateDropSelect from "../../Components/StateDropSelect";
import { IPayer } from "../validator";
import { PatternFormat } from "react-number-format";

const NecPayerForm = ({ handleChange, payer }: { handleChange: (e: ChangeEvent) => void, payer: IPayer }) => {
    return (
        <>
            <BusinessFormsHeading title={"Payer' Info"} currentStep={2} steps={3} />
            <Box mb={"56px"}>

                <Flex gap={"42px"}>
                    <TextInput isRequired flexBasis={"60%"} value={payer.name} label={"Payer's Name"} onChange={handleChange} name={"payer.name"} />
                    {/*<TextInput isRequired flexBasis={"40%"} value={payer.tin} label={"Payer's Tin(Company EiN"} onChange={handleChange} name={"payer.tin"} />*/}
                    <FormControl isRequired mt={"20px"} flexBasis={"40%"}>
                        <FormLabel>
                            {"Payer's Tin(Company EiN)"}
                        </FormLabel>
                        <PatternFormat
                            focusBorderColor="primary"
                            customInput={Input}
                            value={payer.tin}
                            format={"##-#######"}
                            onChange={handleChange}
                            name={"payer.tin"}
                            type={"text"}
                            placeholder={"Ein / Tax Id"} />
                    </FormControl>
                </Flex>
                <Flex>
                    <TextInput isRequired flexBasis={"60%"} value={payer.address.stateAddress} label={"Address"} onChange={handleChange} name={"payer.address.stateAddress"} />
                </Flex>
                <Flex gap={"42px"}>
                    <TextInput isRequired flexBasis={"40%"} value={payer.address.city} label={"City"} onChange={handleChange} name={"payer.address.city"} />
                    <StateDropSelect isRequired flexBasis={"40%"} name={"payer.address.state"} value={payer.address.state} label={"State"} onChange={handleChange} notFormik={true} />
                    <TextInput isRequired flexBasis={"30%"} value={payer.address.zipcode} maxLength={8} label={"Zip Code"} onChange={handleChange} name={"payer.address.zipcode"} />
                </Flex>
            </Box>
        </>
    )
}

export default NecPayerForm