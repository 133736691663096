import {IFormResponse} from "../../Types/Interface/IForm";
import IW2 from "../../Types/Interface/IW2";
import {transformW2Data} from "../../Hooks/useFillW2";
import {reqClient} from "../../Api/reqClient";


const submitW2 = async (data: IW2, guid?: string): Promise<IFormResponse> => {
    const url = `/W2/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const transformedData = transformW2Data(data)
    const body = {
        w2Dto: {
            ...transformedData.dto,
            isCompletedForm: true,
            employerInfo: {...transformedData.dto.employerInfo, isCompletedForm: true},
            w2Employees: [
                {
                    ...transformedData.dto.employee,
                    tax: {...transformedData.dto.employee.tax, isCompletedForm: true},
                }
            ]
        }
    }
    const response = await reqClient.post(url, body)
    return response.data
}

export default submitW2