import {Font, PDFViewer} from "@react-pdf/renderer";
import React, {ReactElement} from "react";
import {Box} from "@chakra-ui/react";


Font.register({
    family: 'Inter',
    fonts: [
        { src: '/fonts/Inter-Thin.ttf', fontWeight: 100 },
        { src: '/fonts/Inter-ThinItalic.ttf', fontWeight: 100, fontStyle: 'italic' },
        { src: '/fonts/Inter-ExtraLight.ttf', fontWeight: 200 },
        { src: '/fonts/Inter-ExtraLightItalic.ttf', fontWeight: 200, fontStyle: 'italic' },
        { src: '/fonts/Inter-Light.ttf', fontWeight: 300 },
        { src: '/fonts/Inter-LightItalic.ttf', fontWeight: 300, fontStyle: 'italic' },
        { src: '/fonts/Inter-Regular.ttf', fontWeight: 400 },
        { src: '/fonts/Inter-Italic.ttf', fontWeight: 400, fontStyle: 'italic' },
        { src: '/fonts/Inter-Medium.ttf', fontWeight: 500 },
        { src: '/fonts/Inter-MediumItalic.ttf', fontWeight: 500, fontStyle: 'italic' },
        { src: '/fonts/Inter-SemiBold.ttf', fontWeight: 600 },
        { src: '/fonts/Inter-SemiBoldItalic.ttf', fontWeight: 600, fontStyle: 'italic' },
        { src: '/fonts/Inter-Bold.ttf', fontWeight: 700 },
        { src: '/fonts/Inter-BoldItalic.ttf', fontWeight: 700, fontStyle: 'italic' },
        { src: '/fonts/Inter-ExtraBold.ttf', fontWeight: 800 },
        { src: '/fonts/Inter-ExtraBoldItalic.ttf', fontWeight: 800, fontStyle: 'italic' },
        { src: '/fonts/Inter-Black.ttf', fontWeight: 900 },
        { src: '/fonts/Inter-BlackItalic.ttf', fontWeight: 900, fontStyle: 'italic' },
    ],
});

const AppDocumentViewer = ({children}: {children: ReactElement}) => {
    return (
        <PDFViewer showToolbar={false} style={{width: "100%", height: "100%"}}>
            {
                children
            }
        </PDFViewer>
    )
}

export default AppDocumentViewer