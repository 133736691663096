import { Box, Checkbox, Flex, FormControl, FormLabel, Input, Select, Text } from "@chakra-ui/react";
import React, { ChangeEvent } from "react"
import { IEmployee, IPayStub, IPaystubInfo } from "../../../../Types/Interface/IPaystub";
import StateDropSelect from "../../../Components/StateDropSelect";
import DropSelect from "../../../Components/Inputs/DropSelect";
import { MaritalStatus } from "../../../../Types/Enums/PaymentModeEnum";
import { PatternFormat } from "react-number-format";
import { useAppDispatch } from "../../../../Hooks/useAppDispatch";
import { setContractor, setHireDate, setMaritalStatus } from "../../../../Store/Slices/paystubSlice";
import { useToast } from "Hooks/useToast";

type EmployeeFormProps = {
    handleChange?: (e: ChangeEvent) => void
    employeeData: IEmployee,
    payStubs?: IPayStub[],
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

type BaseInputProp = {
    value?: number | string,
    isChecked?: boolean,
    handleChange?: (e: ChangeEvent) => void
    setFieldValue?: (field: string, value: string | boolean | number) => void
}

const EmployeeInfoForm = ({ handleChange, employeeData, payStubs,}: EmployeeFormProps) => {
    const dispatch = useAppDispatch()
    const { alert } = useToast()


    const handleSetIsNewHire = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        dispatch(setHireDate({isNewHire: !employeeData.isNewHire}))
    }

    const handleSetHireDate = (e: ChangeEvent<HTMLInputElement>) => {
        const hireDate: Date = new Date(e.target.value)
        if(payStubs !== undefined) {
            
            const lastPayStubDate: Date = new Date(payStubs[0].from)

            if(lastPayStubDate < hireDate)
            {
                alert("error", "Hire date cannot be in the future")
                return
            }
        }

        dispatch(setHireDate({isNewHire: true, hireDate: e.target.value}))
    }

    return (
        <Box mb={"36px"}>
            <EmployeeInfoFormHeading handleChange={handleChange} employeeData={employeeData} />
            <EmployeeNameInput value={employeeData.name} handleChange={handleChange} />
            <Flex gap={"24px"} my={"20px"}>
                <FormControl isRequired>
                    <FormLabel>
                        Last 4 of SSN
                    </FormLabel>
                    <PatternFormat type={"text"} customInput={Input} value={employeeData.ssn} name={"employee.ssn"} onChange={handleChange} format={"####"} />
                </FormControl>
                <EmployeeAddressInput value={employeeData.address} handleChange={handleChange} />
            </Flex>
            <Flex align={"end"} gap={"24px"} mb={"20px"}>
                <EmployeeCityInput value={employeeData.city} handleChange={handleChange} />
                <EmployeeStateSelector value={employeeData.taxState} handleChange={handleChange} />
                <EmployeeZipCodeInput value={employeeData.zipcode} handleChange={handleChange} />
            </Flex>
            <Flex gap={'24px'} mb={"20px"}>
                <FormControl mt={"20px"} isRequired>
                    <FormLabel>
                        Employee ID
                    </FormLabel>
                    <Input type={"text"} value={employeeData.eid} name={"employee.eid"} onChange={handleChange} maxLength={25} />
                </FormControl>
                <DropSelect
                    value={employeeData.maritalStatus}
                    onChange={(e) => dispatch(setMaritalStatus(e.target.value as MaritalStatus))}
                    isRequired label={"Marital Status"} name={`employee.maritalStatus`}>
                    <option disabled>Select Marital Status</option>
                    <option value={MaritalStatus.Married}>{MaritalStatus.Married}</option>
                    <option value={MaritalStatus.Single}>{MaritalStatus.Single}</option>
                </DropSelect>

            </Flex>
            <Checkbox mb={"20px"} mt={"10px"} name={"employee.isNewHire"} onChange={handleSetIsNewHire} isChecked={employeeData.isNewHire}> Check this box to adjust hire date if hired recently</Checkbox>
            {employeeData.isNewHire &&
                <Flex maxWidth={"350px"}>
                    <FormLabel width={"100px"}>
                        Hire Date
                    </FormLabel>
                    <Input type={"date"} value={employeeData.hireDate} name={"employee.hireDate"} onChange={handleSetHireDate} />
                </Flex>
            }
        </Box>
    )
}

const EmployeeInfoFormHeading = ({ employeeData }: EmployeeFormProps) => {
    const dispatch = useAppDispatch()

    return (
        <Flex justifyContent={"space-between"} borderBottomWidth={"0.35px"} borderBottomColor={"black"} pb={"10px"}>
            <Text fontSize={"32px"} fontWeight={"semibold"} color={"#208c55"}>
                Employee&apos;s Information
            </Text>
            <Checkbox onChange={() => dispatch(setContractor(!employeeData.isContractor))} isChecked={employeeData.isContractor} name={`employee.isContractor`} fontSize={"18px"}>Contractor</Checkbox>
        </Flex>
    )
}

const EmployeeNameInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl mt={"20px"} isRequired>
            <FormLabel>
                Employee Name
            </FormLabel>
            <Input type={"text"} value={value} name={"employee.name"} onChange={handleChange} />
        </FormControl>
    )
}

// const EmployeeRoleInput = ({ handleChange, value }: BaseInputProp) => {
//     return (
//         <FormControl>
//             <FormLabel>
//                 Role (Optional)
//             </FormLabel>
//             <Input value={value} onChange={handleChange} name={"employee.role"} type={"text"} />
//         </FormControl>
//     )
// }

const EmployeeAddressInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl isRequired>
            <FormLabel>
                Employee Address
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.address"} type={"text"} />
        </FormControl>
    )
}

const EmployeeCityInput = ({ handleChange, value }: BaseInputProp) => {
    return (
        <FormControl flexBasis={"50%"} isRequired>
            <FormLabel>
                City
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.city"} type={"text"} />
        </FormControl>
    )
}

const EmployeeZipCodeInput = ({ handleChange, value, }: BaseInputProp) => {
    return (
        <FormControl flexBasis={"25%"} isRequired>
            <FormLabel>
                ZIP Code
            </FormLabel>
            <Input value={value} onChange={handleChange} name={"employee.zipcode"} type={"text"} maxLength={5} minLength={5} />
        </FormControl>
    )
}

const EmployeeStateSelector = ({ handleChange, value }: BaseInputProp) => {
    return (
        <StateDropSelect isRequired flexBasis={"40%"} label={"State"} placeholder={"Select state"} notFormik name={"employee.taxState"} value={value} onChange={handleChange} />
    )
}


export default EmployeeInfoForm