import {
    DeductionType,
    MaritalStatus,
    PaymentMode,
    paymentScheduleInfo,
    PaySchedule
} from "../Types/Enums/PaymentModeEnum";
import {v4 as uuidv4} from "uuid";
import {IDeduction, IEarning, IPayStub, IPaystubData} from "../Types/Interface/IPaystub";
import {
    calculateMedicareTax,
    calculateSocialSecurity,
    calculateStatetax,
    calculateYTD,
    getTaxPercentageByStatus
} from "./utils";

import {
    addDays, addMonths, addYears,
    differenceInCalendarMonths,
    differenceInCalendarQuarters,
    differenceInCalendarWeeks, differenceInDays,
    differenceInYears,
    formatDate, getDaysInMonth, getDaysInYear,
    getYear, isEqual, startOfMonth,
    startOfYear
} from "date-fns";


export const generateDeduction = ({ description, type, amount, ytd }: {
    description: string,
    type: DeductionType,
    amount?: number,
    ytd: number
}) => {
    return {
        id: uuidv4(),
        description,
        type,
        amount,
        ytd,
    }
}

export const generateEarning = ({ description, rate, ytd, total, hours, type }: {
    description: string,
    rate?: number,
    type: PaymentMode,
    hours?: number,
    total: number,
    ytd: number
}): IEarning => {
    return {
        id: uuidv4(),
        description,
        rate,
        type,
        hours,
        total,
        ytd
    }
}

export const getFromDate = (currentDate: string | Date, currentPeriodRange: number) => formatDate(addDays(currentDate, -currentPeriodRange + 1), "yyyy-MM-dd")

export const generatePayStub = (
    { currentPeriodRange, currentDate, earnings, deductions, grossPay, grossYtd, netCheck, netPay, check }:
        {
            currentDate: string | Date, earnings: IEarning[],
            deductions: IDeduction[], currentPeriodRange: number,
            grossPay: number, grossYtd: number, netCheck: number, netPay: number, check: number, enableManualTax: boolean
        }): IPayStub => {

    return {
        id: uuidv4(),
        from: getFromDate(currentDate, currentPeriodRange),
        to: formatDate(currentDate, "yyyy-MM-dd"),
        payDate: formatDate(currentDate, "yyyy-MM-dd"),
        earnings, deductions,
        grossPay: {
            amount: grossPay,
            ytd: grossYtd
        },
        netCheck,
        netPay,
        check,
        enableManualTax: false
    }
}

export const calculateCurrentGrossPay = (earnings: IEarning[]): number => {
    return earnings.reduce((acc, current) => acc + current.total, 0)
}

export const calculateCurrentGrossYtd = (earnings: IEarning[]): number => {
    return earnings.reduce((acc, current) => acc + current.ytd, 0)
}

export const calculateEarningsYtd = (prevYtd: number, earning: number) => {
    return prevYtd + earning
}

export const calculateEstimatedAnnualGross = (grossPay: number, currentPayPeriods: number) => {
    return grossPay * currentPayPeriods
}

export const getPayPeriods = (paySchedule: PaySchedule) => paymentScheduleInfo[paySchedule].payPeriods()
export const getPayHours = (paySchedule: PaySchedule) => paymentScheduleInfo[paySchedule].hours
export const getCurrentPeriod = (paySchedule: PaySchedule, initialPayStubEnd: string | Date, startDate: Date | undefined) => paymentScheduleInfo[paySchedule].getCurrentPayPeriod(initialPayStubEnd, startDate)

export const getFIT = (grossPay: number, paySchedule: PaySchedule, status: MaritalStatus) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return annualSalary * getTaxPercentageByStatus(annualSalary, status)
}

export const getStateTax = (grossPay: number, paySchedule: PaySchedule, status: MaritalStatus, stateCode: string) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return calculateStatetax(stateCode, status === MaritalStatus.Married, annualSalary) / payPeriods
}

export const getSocialSecurity = (grossPay: number, paySchedule: PaySchedule) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return calculateSocialSecurity(annualSalary)
}
export const getMedicare = (grossPay: number, paySchedule: PaySchedule) => {
    const payPeriods = getPayPeriods(paySchedule)
    const annualSalary = calculateEstimatedAnnualGross(grossPay, payPeriods)
    return calculateMedicareTax(annualSalary)
}

export const calculateInitialYtd = (paySchedule: PaySchedule, defaultAmount: number, startDate: string | Date) => {
    const prevPayStubPeriod = paymentScheduleInfo[paySchedule].getCurrentPayPeriod(startDate) - 1

    return calculateYTD(defaultAmount, prevPayStubPeriod)
}

export const calculateNetPay = (grossPay: number, deductions: IDeduction[]) => {
    return deductions.reduce((acc, current) => acc - (current.amount ?? 0), grossPay)
}

//Investigate using this for the YTD
// Investigate Bi weekly and test rigorously
export const calculateYTDStartPeriod = (startDate: string | Date, endDate: string | Date, paySchedule: PaySchedule) => {
    let period = 0;
    if (paySchedule == PaySchedule.Annually) {
        period = differenceInYears(endDate, startDate)
    }
    else if (paySchedule == PaySchedule.SemiAnnually) {
        period = Math.floor(differenceInCalendarQuarters(endDate, startDate) / 2)
    }
    else if (paySchedule == PaySchedule.Quarterly) {
        period = differenceInCalendarQuarters(endDate, startDate)
    }
    else if (paySchedule == PaySchedule.Monthly) {
        period = differenceInCalendarMonths(endDate, startDate)
    }
    else if (paySchedule == PaySchedule.Weekly) {
        period = differenceInCalendarWeeks(endDate, startDate)
    }
    // We need to fix this....
    else if (paySchedule == PaySchedule.SemiMonthly) {
        // get amount of months then check for the position of the endDate
        period = differenceInCalendarMonths(endDate, startDate) * 2
        const startOfMonth1 = startOfMonth(startDate)
        const startOfMonth2 = startOfMonth(endDate)

        const differenceStart = differenceInDays(startDate, startOfMonth1)
        const differenceEnd = differenceInDays(endDate, startOfMonth2)
        if (differenceEnd < 15) {
            period -= 1
        }
        if (differenceStart < 15){
            period += 1
        }
    }
    else if (paySchedule == PaySchedule.BiWeekly) {
        period = Math.floor(differenceInCalendarWeeks(endDate, startDate, {}) / 2)
    }

    return period + 1;
}

export const calculateYTDInitial = (periods: number, payPerPeriod: number) => {
    return (periods * payPerPeriod)
}

const calculateYTDStartDate = (payDateStart: string, hireDate: string) => {

    const hireDateYear = getYear(hireDate)
    const currentPayDateStartYear = getYear(payDateStart)

    if (hireDateYear === currentPayDateStartYear) {
        return hireDate;
    }

    return startOfYear(payDateStart)
}

export const getStartDate = (cDate: Date | number | string, nPeriods: number, schedule: PaySchedule) => {
    if (schedule === PaySchedule.Weekly){
        return addDays(cDate, -7 * nPeriods)
    } else if (schedule === PaySchedule.BiWeekly){
        return addDays(cDate, -14 * nPeriods)
    } else if (schedule === PaySchedule.SemiMonthly){
        return addDays(cDate, -15 * nPeriods)
    } else if (schedule === PaySchedule.Monthly){
        return addMonths(cDate, -1 * nPeriods)
    } else if (schedule === PaySchedule.SemiAnnually){
        return addMonths(cDate, -6 * nPeriods)
    } else if (schedule === PaySchedule.Quarterly) {
        return addMonths(cDate, -3 * nPeriods)
    } else {
        return addYears(cDate, -1 * nPeriods)
    }
}

export const getDaysInPaySchedule = ({startDate, schedule}: {startDate: Date | string | number, schedule: PaySchedule}) => {
    if (schedule === PaySchedule.Weekly){
        return 7
    } else if (schedule === PaySchedule.BiWeekly){
        return 14
    } else if (schedule === PaySchedule.SemiMonthly){
        return 15
    } else if (schedule === PaySchedule.Monthly){
        return getDaysInMonth(startDate)
    } else if (schedule === PaySchedule.SemiAnnually){
        const futureDate = addMonths(startDate, 6)
        return differenceInDays(startDate, futureDate)
    } else if (schedule === PaySchedule.Quarterly) {
        const futureDate = addMonths(startDate, 3)
        return differenceInDays(startDate, futureDate)
    } else {
        return getDaysInYear(startDate)
    }
}


export const modifyPayStubs = ({
    payStubs,
    paySchedule,
    stubHours,
    payPeriods,
    maritalStatus,
    paymentMode,
    salary,
    hourlyRate,
    taxState,
    initialEndDate,
    initialStartDate,
    isContractor,
    hireDate,
}: {
    payStubs: IPayStub[],
    isContractor: boolean,
    maritalStatus: MaritalStatus,
    paymentMode: PaymentMode,
    payPeriods: number,
    paySchedule: PaySchedule,
    taxState: string,
    stubHours?: { hours: number, index: number }
    salary?: number,
    hourlyRate?: number,
    initialEndDate?: string | Date,
    initialStartDate?: string | Date,
    hireDate?: string,
}) => {
    // creates a shallow copy of the paystubs
    const reversedStubs = [...payStubs].reverse()
    const defaultAnnualSalary = 48000
    const defaultHourlyRate = 20
    const lastPaystubIndex = reversedStubs.length - 1
    const initialDate = initialStartDate ?? payStubs[payStubs.length - 1].from
    const firstEndDate = initialEndDate ?? payStubs[payStubs.length - 1].to

    let initialEarningYtd = 0
    const deductionsYtd: { [k: number]: number } = {}
    const additionalEarningsYtd: { [k: number]: number } = {}


    let prevStartDate = initialDate
    let prevEndDate = firstEndDate;
    if (typeof initialDate === "object"){
        prevStartDate = new Date(initialDate.valueOf() + initialDate.getTimezoneOffset() * 60 * 1000);
    }
    if (typeof firstEndDate === "object"){
        prevEndDate = new Date(firstEndDate.valueOf() + firstEndDate.getTimezoneOffset() * 60 * 1000);
    }
    let today = new Date();
    today = new Date(today.valueOf() + today.getTimezoneOffset())
    reversedStubs.forEach((paystub, index) => {
        const earning = paystub.earnings[0]
        const currentIndex = lastPaystubIndex - index

        if (index !== 0){
            prevStartDate = addDays(prevEndDate, 1)
            prevEndDate = addDays(prevStartDate, getDaysInPaySchedule({startDate: prevStartDate, schedule: paySchedule}) - 1)
        }

        paystub.to = formatDate(prevEndDate, "yyyy-MM-dd")
        paystub.from = formatDate(prevStartDate, "yyyy-MM-dd")

        paystub.payDate = formatDate(addDays(prevEndDate, 1), "yyyy-MM-dd")
        const ytdStartDate = (hireDate === "" || hireDate === undefined) ?
            formatDate(startOfYear(index === 0 ? paystub.to : reversedStubs[0].to), "yyyy-MM-dd") :
            formatDate(calculateYTDStartDate(paystub.to, hireDate), "yyyy-MM-dd")

        if (paymentMode === PaymentMode.Salary) {
            earning.description = "Salary"
            earning.rate = undefined
            earning.hours = undefined
            earning.type = PaymentMode.Salary
            earning.total = (salary ?? defaultAnnualSalary) / payPeriods
        } else {
            earning.description = "Regular"
            earning.rate = hourlyRate
            earning.type = PaymentMode.Hourly
            earning.hours = stubHours !== undefined && currentIndex === stubHours.index ? stubHours.hours : getPayHours(paySchedule)
            earning.total = (hourlyRate ?? defaultHourlyRate) * earning.hours
        }
        // calculate ytds
        const periods = calculateYTDStartPeriod(ytdStartDate, paystub.to, paySchedule)

        if (index === 0) {
            initialEarningYtd = calculateYTDInitial(periods, earning.total)

        } else {
            if (isEqual(startOfYear(paystub.to),startOfYear(today)) && !isEqual(startOfYear(reversedStubs[index-1].to),startOfYear(today))){
                initialEarningYtd = earning.total
            } else {
                initialEarningYtd += earning.total
            }
        }
        earning.ytd = initialEarningYtd
        paystub.earnings.forEach((earning, earningIndex) => {
            if (earningIndex != 0){
                let earningYtd = additionalEarningsYtd[earningIndex]
                if (index !== 0 && isEqual(startOfYear(paystub.to),startOfYear(today)) && !isEqual(startOfYear(reversedStubs[index-1].to),startOfYear(today))){
                    earningYtd = earning.total
                } else {
                    earningYtd = earningYtd === undefined ? earning.total : earningYtd + earning.total
                }
                additionalEarningsYtd[earningIndex] = earningYtd
                earning.ytd = earningYtd
            }
        })

        const grossPay = paystub.earnings.reduce((acc, current) => acc + current.total, 0)
        Object.assign(
            paystub.grossPay,
            {
                amount: grossPay,
                ytd: paystub.earnings.reduce((acc, current) => acc + current.ytd, 0),
            })
        let netPay = grossPay
        paystub.deductions.forEach((deduction, deductionIndex) => {
            let deductionAmount: number

            if (isContractor) {
                deductionAmount = 0
            }
            else if (deduction.type === DeductionType.FederalTax) {
                if (deduction.amount === undefined) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = !paystub.enableManualTax ? getFIT(grossPay, paySchedule, maritalStatus) / payPeriods : deduction.amount
                }
                // initialFitYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialFitYtd + deductionAmount
                // deduction.ytd = initialFitYtd
            } else if (deduction.type === DeductionType.Medicare) {
                deductionAmount = getMedicare(grossPay, paySchedule) / payPeriods
                // initialMedicareYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialMedicareYtd + deductionAmount
                // deduction.ytd = initialMedicareYtd
            } else if (deduction.type === DeductionType.SocialSecurity) {
                deductionAmount = getSocialSecurity(grossPay, paySchedule) / payPeriods
                // initialSocialYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialSocialYtd + deductionAmount
                // deduction.ytd = initialSocialYtd
            } else if (deduction.type === DeductionType.StateTax) {
                if (deduction.amount === undefined) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = !paystub.enableManualTax ? getStateTax(grossPay, paySchedule, maritalStatus, taxState) : deduction.amount
                }
                // initialStateYtd = index === 0 ? calculateInitialYtd(paySchedule, deductionAmount, ytdStartDate) + deductionAmount : initialStateYtd + deductionAmount
                // deduction.ytd = initialStateYtd
            } else {
                if (deduction.amount === undefined) {
                    deductionAmount = 0
                }
                else {
                    deductionAmount = deduction.amount
                }
                // deduction.ytd = additionalDetailsYtd[deductionIndex] ? additionalDetailsYtd[deductionIndex] + deductionAmount : deductionAmount
                // if (index === 0) {
                //     additionalDetailsYtd[deductionIndex] = deductionAmount
                // }
            }
            deduction.amount = deductionAmount
            let deductionYtd = deductionsYtd[deductionIndex]
            if (index !== 0 && isEqual(startOfYear(paystub.to),startOfYear(today)) && !isEqual(startOfYear(reversedStubs[index-1].to),startOfYear(today))){
                deductionYtd = deduction.amount
            } else {
                deductionYtd = deductionYtd === undefined ? calculateYTDInitial(periods, deductionAmount) : deductionYtd + deductionAmount
            }
            deductionsYtd[deductionIndex] = deductionYtd
            deduction.ytd = deductionYtd
            netPay -= deductionAmount
        })

        paystub.netPay = netPay
        paystub.check = -netPay
        paystub.netCheck = 0
    })
}

export const resizePaystubs = (currentCount: number, data: IPaystubData) => {
    const { payStubs } = data
    const prevCount = payStubs.length
    let prevStub = payStubs[prevCount - 1]
    const defaultEarnings = payStubs[0].earnings
    const defaultDeductions = payStubs[0].deductions
    let prevDate: Date | string | number = payStubs[payStubs.length - 1].from
    let prevEarningYtd = prevStub.earnings[0].ytd

    if (prevCount > currentCount) {
        if (data.info.currentPage + 1 === prevCount){
            data.info.currentPage -= (prevCount - currentCount)
        }
        data.payStubs = [...payStubs.slice(0, currentCount)]
    } else {
        const earnings = defaultEarnings.map((earning, index) => {
            prevEarningYtd -= earning.total
            return generateEarning({
                ...earning,
                hours: index === 0 ? earning.hours : undefined,
                total: index === 0 ? earning.total : 0,
                ytd: index === 0 ? prevEarningYtd : 0
            })
        })
        const deductions = defaultDeductions.map((deduction, index) => {
            return generateDeduction({
                ...deduction,
                amount: index < 4 ? deduction.amount ?? 0 : 0,
            })
        })

        const grossPay = earnings.reduce((acc, current) => acc + current.total, 0)
        const netPay = calculateNetPay(grossPay, deductions)

        const newPaystubs = Array.from({ length: currentCount - prevCount },
            () => {
                const toDate = addDays(prevDate, -1)
                prevDate = addDays(toDate, -getDaysInPaySchedule({startDate: toDate, schedule: data.info.paySchedule}) + 1)
                const currentStub = generatePayStub({
                    check: -netPay,
                    currentPeriodRange: getDaysInPaySchedule({startDate: toDate, schedule: data.info.paySchedule}),
                    deductions,
                    earnings,
                    grossPay,
                    grossYtd: earnings.reduce((acc, current) => acc + current.ytd, 0),
                    netCheck: 0,
                    netPay,
                    currentDate: toDate,
                    enableManualTax: false
                })
                prevStub = currentStub
                return currentStub
            }
        )
        Object.assign(payStubs, [...payStubs, ...newPaystubs])
    }
}