import { states } from "Constants/JsonConstants"
import FormikSelect, { FormikSelectProps } from "./Formik/FormikSelect"
import DropSelect from "./Inputs/DropSelect";
import React from "react"

const StateDropSelect = ({ value, placeholder, name, label, isRequired, notFormik, ...props }: Partial<FormikSelectProps> & {
    notFormik?: boolean
}) => {

    if (notFormik) {
        return (
            <DropSelect
                isRequired={isRequired}
                label={`${label}`}
                placeholder={placeholder}
                {...props}
                value={value}
                name={`${name}`}>
                {
                    states.map(state => <option key={state.initial}
                                                value={state.initial}>{state.label + ` (${state.initial})`}</option>)
                }
            </DropSelect>
        )
    }
    return (
        <FormikSelect
            isRequired
            flexBasis={"30%"}
            {...props}
            placeholder={placeholder} label={`${label}`}
            name={`${name}`}>
            <option disabled>Select a state</option>
            {
                states.map(state => <option key={state.initial}
                    value={state.initial}>{state.label + ` (${state.initial})`}</option>)
            }
        </FormikSelect>
    )
}

export default StateDropSelect