import { Box, Button, Center, Flex, Image, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import arc from "../../../Assets/Images/Arc.png"
import { theme } from "../../../Theme/Theme";
import React, { useState } from "react";
import ga, { HitTypes } from "ga-inits";
import { AppRoutes } from "../../../Routes/AppRoutes";
import { NavLink, } from "react-router-dom"
import TagManager from "react-gtm-module";

type FormInfo = {
    title: string;
    keywords: string;
    description: string;
    path: string;
}
const formsInfo: FormInfo[] = [
    {
        title: "Paystub",
        keywords: "paycheck, payroll, salary slip, earnings statement, wage statement, pay statement, paystub,",
        description: "Document detailing employee earnings, deductions and net pay.",
        path: AppRoutes.payStub,
    },
    {
        title: "W2",
        keywords: "tax form, employee wages, tax withholding, income report, W-2 statement, IRS form, w2",
        description: "Tax form for reporting an employee's annual wages and tax withholdings.",
        path: AppRoutes.w2,
    },
    {
        title: "1099-MISC",
        keywords: "miscellaneous income, freelance income, rental income, royalty payment, contractor earnings, 1099misc, 1099-misc",
        description: "Tax form for reporting miscellaneous income like rent or royalties",
        path: AppRoutes.f1099Misc,
    },
    {
        title: "1099-NEC",
        keywords: "non-employee compensation, freelancer tax form, self-employment income, independent contractor, 1099nec, 1099-nec",
        description: "Tax form for reporting non-employee compensation like freelancers' earnings.",
        path: AppRoutes.f1099Nec,
    },
    {
        title: "Receipt",
        keywords: "payment proof, invoice, transaction record, purchase receipt, sales receipt, expense record",
        description: "Proof of payment for goods or services.",
        path: AppRoutes.receipt,
    },
    {
        title: "Quote",
        keywords: "price estimate, service cost, quotation, business quote, cost estimate, proposal",
        description: "An estimated cost for goods or services before purchase.",
        path: AppRoutes.quotes,
    },

]

const ProductivitySection = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState("");

    const handleSearchBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        e.preventDefault()
        const searchTerm = e.target.value
        setSearchTerm(searchTerm)

        const trackPricingEvent = ga.trackEventBuilder("Pricing")
        trackPricingEvent({ action: "PricingPageLoad", label: "Pricing started", value: 0 })
    }

    const handleClick = (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        console.log("Pricing Button click")
        TagManager.dataLayer({
            dataLayer: {
                event: 'Pricing Button_click',
                category: 'pricing',
                label: 'Homepage Pricing Button'
            }
        })
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {

        if (e.key === 'Enter') {
            const trackSearchEvent = ga.trackEventBuilder("Search")

            trackSearchEvent({ action: "Search", label: searchTerm })
            ga.sendData(HitTypes.PageView, { searchTerm })
            console.log("GTM.............")

        }


    }
    return (
        <Flex direction={"column"} pt={"115px"} w={'100%'} h={"100vh"} pb={"40px"} align={"center"} background={theme.primaryFixed} px={"40px"}>
            <Flex direction={"column"} align={"center"} w={"798px"}>
                <Text fontSize={"6xl"} fontWeight={"bold"} mb={"30px"}>
                    PhcWorkHub
                </Text>
                <Image w={479} objectFit={"contain"} src={arc} />
                <Box h={50}></Box>
                <Text fontSize={"xl"} mb={"24px"} textAlign={"center"}>
                    Effortlessly create personalized documents in a minute.
                </Text>
                <Box position={"relative"} w={"100%"}>
                    <InputGroup background={"white"} borderRadius={"full"} overflow={"clip"}>
                        <Input value={search} onChange={(e) => setSearch(e.target.value)} onBlur={handleSearchBlur} onKeyDown={handleKeyPress} focusBorderColor={"primary"} borderRadius={"full"} px={"16px"} placeholder={"Search for forms"} />
                        <InputRightElement color={"primary"} mx={"16px"} p={"8px"}>
                            {
                                search ? (
                                    <Box h={"24px"} w={"24px"} color={"red"} onClick={() => setSearch("")} cursor={"pointer"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </Box>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor" className="size-6">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                    </svg>
                                )
                            }
                        </InputRightElement>
                    </InputGroup>
                    {
                        search && (
                            <SearchDropDown search={search} />
                        )
                    }
                </Box>
                <Text my={"48px"}>Search for forms you want to create</Text>
                <Button onClick={handleClick} w={"413px"} background={"primary"} borderRadius={"full"} color={theme.onSecondary}>
                    Create Unlimited forms
                </Button>
            </Flex>
        </Flex>
    )
}

const SearchDropDown = ({ search }: { search: string }) => {
    const results = formsInfo.filter((data) => data.keywords.toLowerCase().includes(search.toLowerCase()))
    return (
        <Box
            h={"max-content"}
            maxH={"230px"}
            position={"absolute"}
            background={"white"}
            insetX={0}
            overflow={"clip"}
            overflowY={'scroll'}
            borderRadius={"12px"}
            marginTop={"12px"}
            boxShadow={"md"}
            zIndex={10000}>
            {
                results.length != 0 ? (
                    results.map((data) => (
                        <NavLink key={data.path} to={data.path}>
                            <Box py={"8px"} px={"24px"} _hover={{ background: "gray.100" }}>
                                <Text mb={"8px"} color={"primary"} fontSize={"16px"} fontWeight={"semibold"}>{data.title}</Text>
                                <Text fontSize={"12px"}>{data.description}</Text>
                            </Box>
                        </NavLink>
                    ))
                ) : (
                    <Center h={"230px"}>
                        No Form Found
                    </Center>
                )
            }
        </Box>
    )
}

export default ProductivitySection