import {useEffect, useState} from "react";
import verifyPayment from "../Services/api/verifyPayment";
import {useAppSelector} from "./useAppSelector";
import {IDocumentCheckout} from "../Types/Interface/IRequestBody";

const useVerifyPayment = (hasPaid?: boolean, session?: IDocumentCheckout) => {
    const [checkoutLoading, setCheckOutLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false)
    const guid = useAppSelector(state => state.document.guid)
    const email = useAppSelector(state => state.document.email)

    useEffect(() => {
        if (hasPaid && session && guid) {
            setCheckOutLoading(true);
            verifyPayment(guid, session, email).then((res) => {
                setIsSuccess(res)
            }).catch(() => {

            }).finally(() => {
                setCheckOutLoading(false)
            })
        }
    }, [session, hasPaid])

    return {isSuccess, checkoutLoading}
}


export default useVerifyPayment