import {IFormResponse} from "../../Types/Interface/IForm";
import {reqClient} from "../../Api/reqClient";
import {transformNecData} from "../../Hooks/useFill1099Nec";
import {I1099Nec} from "../../Pages/1099Nec/validator";

const submit1099Nec = async (data: I1099Nec, guid?: string): Promise<IFormResponse> => {
    const url = `/_1099/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const transformedData = transformNecData(data)
    const body = {
        "_1099Dto": {
            ...transformedData._1099Dto,
            payer: {...transformedData._1099Dto.payer, isCompletedForm: true},
            recipient: {
                ...transformedData._1099Dto.recipient, isCompletedForm: true
            },
            _1099NECOptions: {
                ...transformedData._1099Dto._1099NECOptions, isCompletedForm: true
            }
        }
    }

    const response = await reqClient.post(url, body)
    return response.data
}

export default submit1099Nec