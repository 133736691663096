import {IFormResponse} from "../../Types/Interface/IForm";
import {IPaystubData} from "../../Types/Interface/IPaystub";
import {DeductionType, MaritalStatus, PaymentMode} from "../../Types/Enums/PaymentModeEnum";
import {reqClient} from "../../Api/reqClient";

const submitPaystub = async (data: IPaystubData, guid?: string): Promise<IFormResponse> => {
    const url = `/create_paystub/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const body = {
        payStubDto: {
            company: {
                ...data.company,
                isCompletedForm: true,
                address: {
                    id: 0,
                    addressLine1: data.company.address,
                    addressLine2: "",
                    postalCode: data.company.zipcode,
                    city: data.company.city,
                    regionOrState: data.company.state,
                    country: "united state",
                    isCompletedForm: true,
                },
                websiteUrl: "",
                phoneNumber: "",
                ein: "",
                taxId: "",
                isContractor: data.employee.isContractor
            },
            employee: {
                id: 0,
                name: data.employee.name,
                firstName: data.employee.name,
                lastName: "",
                middleName: "",
                addressLine1: data.employee.address,
                zip: data.employee.zipcode,
                city: data.employee.city,
                state: data.employee.taxState,
                ssn: data.employee.ssn,
                employeeNumber: data.employee.eid,
                role: "",
                email: "",
                phoneNumber: "",
                isMarried: data.employee.maritalStatus === MaritalStatus.Married
            },
            schedule: 1,
            paymentMode: data.info.paymentMode === PaymentMode.Hourly ? 0 : 1,
            hourlyRate: PaymentMode.Hourly === data.info.paymentMode ? data.info.hourlyRate : data.info.salary,
            earnings: data.payStubs.map((payStub, index) => {
                    const medicare = payStub.deductions.find((deduction) => deduction.type === DeductionType.Medicare)
                    const socialSecurity = payStub.deductions.find((deduction) => deduction.type === DeductionType.SocialSecurity)
                    const incomeFedTax = payStub.deductions.find((deduction) => deduction.type === DeductionType.FederalTax)
                    const stateTax = payStub.deductions.find((deduction) => deduction.type === DeductionType.StateTax)
                    return {
                        id: index,
                        isCompletedForm: true,
                        employeeId: 0,
                        companyId: 0,
                        payDate: new Date(payStub.payDate).toISOString(),
                        from: new Date(payStub.from).toISOString(),
                        to: new Date(payStub.to).toISOString(),
                        totalHours: data.info.paymentMode === PaymentMode.Hourly ? payStub.earnings[0].hours : 1,
                        additionalEarnings: payStub.earnings.slice(1).map((earning) => (
                            {
                                description: earning.description,
                                rate: earning.rate,
                                totalHoursOrQuantity: earning.hours,
                                earningStubId: index,
                                employeeId: 0
                            }
                        )),
                        customDeductions: payStub.deductions.slice(4).map((deduction) => (
                            {
                                id: 0,
                                isCompletedForm: true,
                                total: deduction.amount,
                                earningStubId: index,
                                description: deduction.description,
                            }
                        )),
                        medicare: medicare?.amount,
                        stateTax: stateTax?.amount,
                        socialSecurity: socialSecurity?.amount,
                        federalTax: incomeFedTax?.amount,
                        exemption_allowance: 0,
                        adviceNumber: 0,
                    }
                }
            )
        }
    }
    const response = await reqClient.post(url, body)
    return response.data
}


export default submitPaystub