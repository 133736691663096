import React, {useEffect, useState} from "react"
import {useAppSelector} from "../../Hooks/useAppSelector";
import {DocumentMap} from "../../Types/Enums/documentTypeEnums";
import {Center, Flex, Image, Box, Text} from "@chakra-ui/react";
import {IDocumentCheckout} from "../../Types/Interface/IRequestBody";
import {checkoutMode} from "../../Types/Enums/checkoutModeEnum";
import {useGetPriceByDocumentTypeQuery} from "../../Services";
import logo from "../../Assets/Images/logo.png";
import AppLayout from "../../Layouts/AppLayout";


const ReturnPage = () => {
    const formType = useAppSelector(state => state.document.formType);
    const documentType = useAppSelector((state) => state.document.documentType);
    const coinbaseSession = useAppSelector((state) => state.document.coinbaseSession);
    const { data: documentPrice } = useGetPriceByDocumentTypeQuery(documentType);
    const amount = documentPrice?.documentPrice.newPrice;
    const [session, setSession] = useState<IDocumentCheckout>();

    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        setSession({
            sessionId: sessionId ?? `${coinbaseSession}`,
            checkoutMode: sessionId ? checkoutMode.stripe : checkoutMode.coinbase,
            amount: amount ?? 0
        })
    }, [])

  return (
      <AppLayout isForm>
          <Flex h={"70vh"} mt={"100px"} justifyContent={"center"}>
              <Flex direction={"column"} align={"center"}>
                  <Flex direction={"column"} align={"center"} h={"200px"} py={'12px'}>
                      <Image src={logo} h={"calc(100% - 16px)"} objectFit={"contain"} />
                      <Text flexGrow={'1'} color={"primary"}>PhcWorkHub</Text>
                  </Flex>
                  <Text fontSize={"3xl"} w={"400px"} align={"center"} mb={"16px"}>
                      Thank you for choosing us. Here is your document.
                  </Text>
                  {
                      formType ? DocumentMap({formType, hasPaid: true, session}) : <Center>No Document available for checkout</Center>
                  }
              </Flex>
          </Flex>
      </AppLayout>
  )
}


export default ReturnPage