import {IFormResponse} from "../../Types/Interface/IForm";
import {IQuotes} from "../../Types/Interface/IQuotes";
import {reqClient} from "../../Api/reqClient";

const submitQuote = async (data: IQuotes, guid?: string): Promise<IFormResponse> => {
    const url = `/Quotes/${guid ?? "00000000-0000-0000-0000-000000000000"}`
    const body = {
        taxAmount: data.taxAmount,
        discountAmount: data.discountAmount,
        discountPercentage: data.discountPercentage,
        from: {
            id: data.from.id,
            isCompletedForm: true,
            address: {
                id: data.from.address.id,
                isCompletedForm: true,
                addressLine1: data.from.address.addressLine1,
                addressLine2: data.from.address.addressLine2,
                postalCode: data.from.address.postalCode,
                city: data.from.address.city,
                regionOrState: data.from.address.regionOrState,
                country: "United State"
            },
            name: data.from.name,
            email: data.from.email,
            websiteUrl: data.from.websiteUrl,
            phoneNumber: data.from.phoneNumber,
            logo: data.from.logo,
            ein: "",
            taxId: "",
            isContractor: true
        },
        "for": {
            id: data.for.id,
            isCompletedForm: true,
            address: {
                id: data.for.address.id,
                isCompletedForm: true,
                addressLine1: data.for.address.addressLine1,
                addressLine2: data.for.address.addressLine2,
                postalCode: data.for.address.postalCode,
                city: data.for.address.city,
                regionOrState: data.for.address.regionOrState,
                country: "United state"
            },
            name: data.for.name,
            email: data.for.email,
            websiteUrl: data.for.websiteUrl,
            phoneNumber: data.for.phoneNumber,
            logo: data.for.logo,
            ein: "",
            taxId: "",
            isContractor: true
        },
        lineItems: data.lineItems.map((lineItem, index) => ({ ...lineItem, id: index, isCompletedForm: true })),
        "quoteHtmlString": "",
        "email": data.from.email
    }
    const response = await reqClient.post(url, body)
    return response.data
}


export default submitQuote