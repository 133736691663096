import {IDocumentCheckout} from "../../Types/Interface/IRequestBody";
import {reqClient} from "../../Api/reqClient";

const verifyPayment = async (guid: string, session: IDocumentCheckout, email?: string, ): Promise<boolean> => {
    const { checkoutMode, sessionId, amount } = session
    const url = `/payments/${sessionId}/${amount}/${checkoutMode}/${guid}`
    const body = {
        email: email ?? ""
    }
    const response = await reqClient.post(url, body)
    return response.status === 200
}


export default verifyPayment